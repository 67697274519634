import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { fornecedorStore, userToolStore } from '@/utils/store-accessor';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.search = '';
        this.headers = [
            { text: 'CPF / CNPJ', value: 'cnpj' },
            { text: 'Nome Fantasia', value: 'nome_fantasia' },
            { text: 'Razão Social', value: 'razao_social' },
            { text: 'Ações', value: 'actions' },
        ];
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Fornecedores) {
            return userToolStore.userToolsIndexed.Fornecedores[0].allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Fornecedores) {
            return userToolStore.userToolsIndexed.Fornecedores[0]
                .allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Fornecedores) {
            return userToolStore.userToolsIndexed.Fornecedores[0].allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Fornecedores) {
            return userToolStore.userToolsIndexed.Fornecedores[0]
                .allow_delete;
        }
    }
    get fornecedores() {
        return fornecedorStore.fornecedores;
    }
    async mounted() {
        await fornecedorStore.getFornecedores();
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-fornecedores-edit', params: { id } });
    }
    async deleteItem(id) {
        this.$swal({
            title: 'Confirma a exclusão do Fornecedor?',
            text: 'Após exclusão o fornecedor não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await fornecedorStore.deleteFornecedor(id);
                await fornecedorStore.getFornecedores();
            }
        });
    }
};
List = __decorate([
    Component
], List);
export default List;
